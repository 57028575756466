<template>
  <b-row>
    <b-col
      cols="12"
      md="3"
    >
      <b-form-group
        label="İş Emri No"
        label-for="work_no"
      >
        <b-form-input
          id="work_no"
          v-model="filterParams.work_no"
          placeholder="İş Emri No"
          @keydown.enter="getDataList"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Lokasyon"
        label-for="id_com_location"
      >
        <v-select
          id="id_com_location"
          v-model="filterParams.id_com_location"
          :options="locations"
          label="title"
          :reduce="item => item.id"
          placeholder="Lokasyon"
          @input="getDataList"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="auto"
    >
      <b-button
        variant="primary"
        class="mt-2"
        @click="getDataList"
      >
        <FeatherIcon icon="FilterIcon" />
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterCard',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    getDataList: {
      type: Function,
      required: true,
    },
  },
  computed: {
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    filterParams() {
      return this.$store.getters['serviceWorkOrders/filterParams']
    },
  },
}
</script>
